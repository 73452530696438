import { makeVar } from '@apollo/client';
import { ResultOf } from '@graphql-typed-document-node/core';

import { getUserConfig } from '@phoenix7dev/utils-fe';

import { config } from '../config';
import { BonusStatus, GameMode, PopupOpeningTypes } from '../consts';
import { icons } from '../slotMachine/config/icons';
import { lines } from '../slotMachine/config/lines';
import { reels } from '../slotMachine/config/reelLayout';
import { IBalance, IBonusData, IPredicament, ISettledBet, ReelSet } from '../types';

import { IConfig, IProgress, ISlotConfig, IStressful } from './d';
import { betFragment } from './fragment';

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<IProgress>({
  status: 0,
  wasLoaded: false,
});

export const setIsSoundOn = makeVar<boolean>(getUserConfig<IConfig>('config', 'isSoundOn', true));

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setPrevReelsPosition = makeVar<number[]>([0, 0, 0, 0, 0]);

export const setLastRegularReelPositions = makeVar<number[]>([0, 0, 0, 0, 0]);

export const setPrevReelSet = makeVar<ReelSet>({ type: GameMode.BASE_GAME, layout: [] });

export const setIsSpinInProgress = makeVar<boolean>(false);

export const setIsMobile = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(25);

export const setCurrency = makeVar<string>('FUN');

export const setBrokenGame = makeVar<boolean>(false);

export const setBrokenBuyFeatureGame = makeVar<string>('');

export const setCurrentFreeSpinsTotalWin = makeVar<number>(0);

export const setCoinAmount = makeVar<number>(1);

export const setCoinValue = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(config.autoplay.defaultAutoSpinsAmount);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(true);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setIsReSpinsWin = makeVar<boolean>(false);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setIsContinueAutoSpinsAfterFeature = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

// todo replace with real backend logic
export const setFreeSpinsTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.BASE_GAME);

export const setReelSetType = makeVar<string>('');

export const setDefaultReelSetId = makeVar<GameMode>(GameMode.BASE_GAME);

export const setNextResult = makeVar<ISettledBet | null>(null);

export const setUserLastBetResult = makeVar<ResultOf<typeof betFragment> | null>(null);

export const setUserBalance = makeVar<IBalance>({
  amount: 0,
  currency: '',
});

export const setCurrentBonus = makeVar<IBonusData>({
  packageId: '',
  gameMode: GameMode.BASE_GAME,
  rounds: 10,
  roundsPlayed: 0,
  state: BonusStatus.CLOSED,
  coinAmount: 0,
  coinValue: 0,
  originalReelPositions: [],
  isBuyFeature: false,
});

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setSlotConfig = makeVar<ISlotConfig>({
  slotSettings: {
    betLines: { max: 1, min: 1 },
    cols: 4,
    rows: 1,
    globalCoinAmountMultiplier: 25,
    startingPositions: [0, 0, 0, 0],
    buyFeaturePackages: [],
  },
  slotId: '',
  sessionId: '',
  clientSlotSettings: {
    autoplaySettings: {
      conditions: {
        stopIfBalanceIncreasesBy: { enabled: false, multipliers: [] },
        stopIfFeatureIsWon: { enabled: false },
        stopIfSingleWinExceeds: { enabled: false, multipliers: [] },
        stopOnAnyWin: { enabled: false },
        stopIfBalanceDecreasesBy: {
          enabled: false,
        },
      },
      options: [],
    },
    betCoinAmountSettings: [],
    coinValueSettings: [],
    quickBetCoinAmountSettings: [],
    wagerExpirationDurationSeconds: 0,
    realBetCoinAmounts: [],
  },
  lineSet: {
    coinAmountMultiplier: 0,
    id: '',
    lines: [],
  },
  lines: lines,
  reels: reels,
  icons: icons,
});

//export const setBonuses = makeVar<ResultOf<typeof getBonuses>['bonuses']>([]);

export const setIsTurboSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isTurboSpin', false));

export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsOpenedMessageBanner = makeVar<boolean>(false);
export const setIsInTransition = makeVar<boolean>(false);

export const setIsSpinShortCut = makeVar<boolean>(false);

export const setIsBuyFeaturePopupOpened = makeVar<boolean>(false);

export const setIsBuyFeatureSpin = makeVar<boolean>(false);

export const setIsOpeningScenePlaying = makeVar<boolean>(false);
export const setIsOpenInfoPopup = makeVar<boolean>(false);
export const setIsOpenBetSettingsPopup = makeVar<boolean>(false);
export const setIsOpenHistoryPopup = makeVar<boolean>(false);

export const setIsOpenAutoplayPopup = makeVar<boolean>(false);

export const setIsPopupOpeningInProgress = makeVar<PopupOpeningTypes>(PopupOpeningTypes.NONE);

export const setIsAllowForceReelStop = makeVar<boolean>(false);

export const setIsTimeoutErrorMessage = makeVar(false);

export const setResumeDuringFreeSpin = makeVar<boolean>(false);

export const setLastBaseReelPositions = makeVar<number[]>([0, 0, 0, 0, 0]);

export const setForceReelStop = makeVar<boolean>(false);

export const setIsBuyFeaturePurchased = makeVar<boolean>(false);

//Fish
export const setFishingResult = makeVar<IPredicament | null>(null);
export const setIsWheelSpinning = makeVar<boolean>(false);
export const setIsSuperBuyFeature = makeVar<boolean>(false);
