import { TrackEntry } from '@pixi-spine/all-4.1';
import { Container } from 'pixi.js';

import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { MessageFreeSpinsBannerProps } from '../../global';
import Tween from '../animations/tween';
import { TickerSpine } from '../components/spine/index';
import { EventTypes, eventManager } from '../config';
import { FishingLayout } from '../fishing/config';
import { FishIcon } from '../fishing/icon/fishIcon';

import { BaseMessageBanner } from './baseMessageBanner';

const rouletteNmlConver: Record<FishKind, SpineInterface['prize']['animations'][]> = {
  Rank1: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_c1', 'roule_last_pt01'],
  Rank2: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_d1', 'roule_last_pt02'],
  Rank3: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_c1', 'roule_last_pt03'],
  Rank4: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_d1', 'roule_last_pt04'],
  Rank5: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_c1', 'roule_last_pt05'],
  Rank6: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_d1', 'roule_last_pt06'],
  Rank7: ['roule_a1', 'roule_a1', 'roule_b1', 'roule_c1', 'roule_last_pt07'],
};
const rouletteSPConver: Record<FishKind, SpineInterface['prize']['animations'][]> = {
  Rank1: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_c2', 'roule_last_pt01'],
  Rank2: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_d2', 'roule_last_pt02'],
  Rank3: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_c2', 'roule_last_pt03'],
  Rank4: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_d2', 'roule_last_pt04'],
  Rank5: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_c2', 'roule_last_pt05'],
  Rank6: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_d2', 'roule_last_pt06'],
  Rank7: ['roule_a2', 'roule_a2', 'roule_b2', 'roule_c2', 'roule_last_pt07'],
};

export class MessageFreeSpinsBanner extends BaseMessageBanner {
  protected override props: MessageFreeSpinsBannerProps;

  private icon: FishIcon;
  private title: TickerSpine<'prize'>;
  private roulette: TickerSpine<'prize'>;
  private bannerContainer: Container;

  constructor(props: MessageFreeSpinsBannerProps) {
    super(props);
    this.props = props;

    this.icon = new FishIcon(this.props.fishRank);

    this.title = new TickerSpine('prize');
    this.title.state.setAnimation(0, 'title', true);

    this.roulette = new TickerSpine('prize');

    this.bannerContainer = new Container();
  }

  public override init(): MessageFreeSpinsBanner {
    super.init();

    this.bannerContainer.addChild(this.icon, this.title);

    this.addChild(this.bannerContainer, this.roulette);

    this.roulette.state.setEmptyAnimation(0);

    this.bannerContainer.visible = false;
    this.interactive = false;

    const delay = Tween.createDelayAnimation(100);
    delay.addOnComplete(() => {
      this.startRoulette();
    });
    delay.start();
    return this;
  }

  private startRoulette() {
    const animTbl = this.props.isSuperBuyFeature
      ? rouletteSPConver[this.props.fishRank]
      : rouletteNmlConver[this.props.fishRank];

    this.roulette.state.setAnimation(0, animTbl[0]!, false);
    this.roulette.state.addAnimation(0, animTbl[1]!, false);
    this.roulette.state.addAnimation(0, animTbl[2]!, false);
    this.roulette.state.addAnimation(0, animTbl[3]!, false);
    this.roulette.state.addAnimation(0, animTbl[4]!, false);

    this.roulette.state.addListener({
      complete: (entry: TrackEntry) => {
        if (entry.animation?.name.includes('roule_last')) {
          const delay = Tween.createDelayAnimation(300);
          delay.addOnComplete(() => {
            this.bannerContainer.visible = true;
            this.roulette.visible = false;
            this.interactive = true;
            eventManager.emit(EventTypes.FISHING_CHALLENGE_WAIT_START);
          });
          delay.start();
        }
      },
    });
    eventManager.emit(EventTypes.FISHING_ROULETTE_START);
  }

  public override handleResize(width: number, height: number): void {
    super.handleResize(width, height);
    if (height > width) {
      this.x = FishingLayout.Banner.Portrait.Base.x;
      this.y = FishingLayout.Banner.Portrait.Base.y;

      this.title.x = FishingLayout.Banner.Portrait.title.x;
      this.title.y = FishingLayout.Banner.Portrait.title.y;

      this.title.scale.set(FishingLayout.Banner.Portrait.titleScale);

      this.scale.set(FishingLayout.Banner.Portrait.BaseScale);
    } else {
      this.x = FishingLayout.Banner.LandScape.Base.x;
      this.y = FishingLayout.Banner.LandScape.Base.y;

      this.title.x = FishingLayout.Banner.LandScape.title.x;
      this.title.y = FishingLayout.Banner.LandScape.title.y;

      this.title.scale.set(FishingLayout.Banner.LandScape.titleScale);

      this.scale.set(FishingLayout.Banner.LandScape.BaseScale);
    }
  }
}
