import * as PIXI from 'pixi.js';

import { FishKind } from '../../config';
import { GameMode } from '../../consts';
import i18n from '../../i18next';
import { isBaseGameMode, isFishingChallengeMode } from '../../utils';
import AutoResizeText from '../components/autoResizeText';
import { SpriteButton } from '../components/button';
import ViewContainer from '../components/container';
import { layerFishingPrize } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import { FishingLayout } from './config';
import { FishIcon } from './icon/fishIcon';
import { gambleSelectDescriptionTextStyles, gambleSelectTextStyles, gambleSelectTitleTextStyles } from './textStyle';

class GambleSelect extends ViewContainer {
  private base: TickerSpine<'gamble'>;
  private offL: TickerSpine<'gamble'>;
  private offR: TickerSpine<'gamble'>;
  private iconL: FishIcon;
  private iconR: FishIcon;

  private textGamble: AutoResizeText;
  private textCollect: AutoResizeText;

  private title: AutoResizeText;
  private description: AutoResizeText;

  private container: PIXI.Container;

  private okBtn: SpriteButton;
  private cancelBtn: SpriteButton;

  private gambleContainerBtn: PIXI.Container;
  private collectContainerBtn: PIXI.Container;

  private layout: 'portrait' | 'landscape';

  private state: 'select' | 'gamble' | 'collect';

  private collectRank: FishKind;
  private gambleRank: FishKind;

  constructor() {
    super();

    this.layout = 'landscape';
    this.state = 'select';
    this.collectRank = 'Rank1';
    this.gambleRank = 'Rank1';

    this.base = new TickerSpine('gamble');
    this.base.state.setAnimation(0, 'base', true);

    this.offL = new TickerSpine('gamble');
    this.offL.state.setAnimation(0, 'off_l', true);
    this.offL.visible = false;

    this.offR = new TickerSpine('gamble');
    this.offR.state.setAnimation(0, 'off_r', false);
    this.offR.visible = false;

    this.iconL = new FishIcon('Rank1');
    this.iconL.scale.set(FishingLayout.GambleSelect.iconScale);

    this.iconR = new FishIcon('Rank1');
    this.iconR.scale.set(FishingLayout.GambleSelect.iconScale);

    this.textGamble = new AutoResizeText(i18n.t('fishing.gambleSelect.gamble'), gambleSelectTextStyles);
    this.textGamble.anchor.set(0.5);

    this.textCollect = new AutoResizeText(i18n.t('fishing.gambleSelect.collect'), gambleSelectTextStyles);
    this.textCollect.anchor.set(0.5);

    this.gambleContainerBtn = this.initButtonContainer(
      FishingLayout.GambleSelect.common.gambleBtnArea.x,
      FishingLayout.GambleSelect.common.gambleBtnArea.y,
      FishingLayout.GambleSelect.common.gambleBtnArea.w,
      FishingLayout.GambleSelect.common.gambleBtnArea.h,
      this.onGambleClick.bind(this),
    );

    this.collectContainerBtn = this.initButtonContainer(
      FishingLayout.GambleSelect.common.collectBtnArea.x,
      FishingLayout.GambleSelect.common.collectBtnArea.y,
      FishingLayout.GambleSelect.common.collectBtnArea.w,
      FishingLayout.GambleSelect.common.collectBtnArea.h,
      this.onCollectClick.bind(this),
    );

    this.container = new PIXI.Container();
    this.container.scale.set(FishingLayout.GambleSelect.landscape.containerScale);
    this.container.addChild(
      this.base,
      this.iconL,
      this.iconR,
      this.textGamble,
      this.textCollect,
      this.offL,
      this.offR,
      this.gambleContainerBtn,
      this.collectContainerBtn,
    );

    this.title = new AutoResizeText(i18n.t('fishing.gambleSelect.title'), gambleSelectTitleTextStyles);
    this.title.anchor.set(0.5);

    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();

    this.description = new AutoResizeText(
      i18n.t('fishing.gambleSelect.description'),
      gambleSelectDescriptionTextStyles,
    );
    this.description.anchor.set(0.5);

    this.addChild(this.container, this.title, this.description, this.cancelBtn, this.okBtn);

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.on(EventTypes.FISHING_GAMBLE_SELECT_START, this.start.bind(this));

    this.parentLayer = layerFishingPrize;

    this.visible = false;
  }
  private onGambleClick() {
    this.gambleContainerBtn.interactive = true;
    this.collectContainerBtn.interactive = true;
    this.cancelBtn.visible = true;
    this.offR.visible = true;
    this.offL.visible = false;
    this.okBtn.visible = true;
    this.description.visible = false;

    this.state = 'gamble';

    eventManager.emit(EventTypes.FISHING_G_SELECT_SELECTED, true);
  }

  private onCollectClick() {
    this.gambleContainerBtn.interactive = true;
    this.collectContainerBtn.interactive = true;
    this.offL.visible = true;
    this.offR.visible = false;
    this.cancelBtn.visible = true;
    this.okBtn.visible = true;
    this.description.visible = false;

    this.state = 'collect';
    eventManager.emit(EventTypes.FISHING_G_SELECT_SELECTED, true);
  }

  private selectView() {
    this.gambleContainerBtn.interactive = true;
    this.collectContainerBtn.interactive = true;
    this.offL.visible = false;
    this.offR.visible = false;
    this.cancelBtn.visible = false;
    this.okBtn.visible = false;
    this.description.visible = true;

    this.state = 'select';
    eventManager.emit(EventTypes.FISHING_G_SELECT_SELECTED, false);
  }

  private initButtonContainer(x: number, y: number, w: number, h: number, callback: () => void) {
    const container = new PIXI.Container();
    container.interactive = true;
    container.buttonMode = true;
    container.hitArea = new PIXI.Rectangle(x, y, w, h);
    container.on('click', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) callback();
    });
    container.on('touchstart', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) callback();
    });

    container.addListener('mouseover', () => {});
    container.addListener('mouseout', () => {});
    container.addListener('mousedown', () => {});
    container.addListener('mouseup', () => {});
    return container;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton(
      {
        default: 'gamble_btn_back',
        hover: 'gamble_btn_back_over',
        press: 'gamble_btn_back_push',
        disable: 'gamble_btn_back',
      },
      () => {
        cancelBtn.disable = false;
        this.selectView();
      },
    );
    cancelBtn.position.copyFrom(FishingLayout.GambleSelect.landscape.cancelBtn);
    cancelBtn.anchor.set(0.5);
    cancelBtn.visible = false;

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton(
      {
        default: 'gamble_btn_ok',
        hover: 'gamble_btn_ok_over',
        press: 'gamble_btn_ok_push',
        disable: 'gamble_btn_ok_disable',
      },
      () => {
        okBtn.disable = false;

        this.visible = false;
        if (this.state === 'gamble') {
          eventManager.emit(EventTypes.GAMBLE_SELECTED);
        } else {
          eventManager.emit(EventTypes.GAMBLE_CANCEL_SELECTED);
        }
      },
    );
    okBtn.position.copyFrom(FishingLayout.GambleSelect.landscape.okBtn);
    okBtn.anchor.set(0.5);
    okBtn.visible = false;

    return okBtn;
  }

  private start(fishRank: FishKind) {
    this.collectRank = fishRank;

    this.setFishRank();
    this.visible = true;
  }

  private setFishRank() {
    const fishUpgradeTable: Record<FishKind, FishKind> = {
      Rank1: 'Rank1',
      Rank2: 'Rank1',
      Rank3: 'Rank2',
      Rank4: 'Rank3',
      Rank5: 'Rank4',
      Rank6: 'Rank5',
      Rank7: 'Rank6',
    };

    const nextRank = fishUpgradeTable[this.collectRank];
    this.gambleRank = nextRank;

    this.iconL.setFishKind(this.gambleRank);
    this.iconR.setFishKind(this.collectRank);
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishRank?: FishKind;
  }) {
    if (isFishingChallengeMode(settings.mode)) {
      this.visible = false;
    } else if (isBaseGameMode(settings.mode)) {
      this.selectView();
      this.visible = false;
    } else {
      this.visible = false;
    }
  }

  private applicationResize = (width: number, height: number): void => {
    const layout = width > height ? FishingLayout.GambleSelect.landscape : FishingLayout.GambleSelect.portrait;
    const commonPos = FishingLayout.GambleSelect.common;

    this.container.scale.set(layout.containerScale);

    this.base.position.copyFrom(commonPos.base);
    this.offL.position.copyFrom(commonPos.base);
    this.offR.position.copyFrom(commonPos.base);
    this.iconL.position.copyFrom(commonPos.iconL);
    this.iconR.position.copyFrom(commonPos.iconR);
    this.textGamble.position.copyFrom(commonPos.textL);
    this.textCollect.position.copyFrom(commonPos.textR);

    this.title.position.copyFrom(layout.title);
    this.description.position.copyFrom(layout.description);
    this.okBtn.position.copyFrom(layout.okBtn);
    this.cancelBtn.position.copyFrom(layout.cancelBtn);

    this.container.x = layout.container.x;
    this.container.y = layout.container.y;

    if (width > height) {
      this.layout = 'landscape';
    } else {
      this.layout = 'portrait';
    }
  };
}

export default GambleSelect;
