import { Bone, Event, TrackEntry } from '@pixi-spine/all-4.1';

import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import { setFishingResult, setIsWheelSpinning } from '../../gql/cache';
import i18n from '../../i18next';
import { isFishingChallengeMode, transitionToBaseGame } from '../../utils';
import Tween from '../animations/tween';
import { BgmControl } from '../bgmControl/bgmControl';
import AutoResizeText from '../components/autoResizeText';
import ViewContainer from '../components/container';
import { layerFishingChallenge } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import { FishingLayout } from './config';
import FishMeter from './fishMeter';
import { fishingChallengeRoundPortraitTextStyles, fishingChallengeRoundTextStyles } from './textStyle';

class FishingChallenge extends ViewContainer {
  private fishRank: FishKind;
  private sign: TickerSpine<'fc_wheel'>;
  private wheel: TickerSpine<'fc_wheel'>;
  private flash: TickerSpine<'fc_wheel'>;
  private frame: TickerSpine<'fc_wheel'>;
  private nice: TickerSpine<'fc_wheel'>;
  private roundSpine: TickerSpine<'fc_wheel'>;

  private roundText: AutoResizeText;

  private wheelBone: Bone;

  private meter: FishMeter;

  private targetPos: number;

  constructor() {
    super();

    this.fishRank = 'Rank1';
    this.targetPos = 0;

    this.sign = new TickerSpine('fc_wheel');
    this.sign.position.copyFrom(FishingLayout.Challenge.Sign.Base);
    this.sign.state.setAnimation(0, 'sgin_wait', true);

    this.wheel = new TickerSpine('fc_wheel');
    this.wheel.position.copyFrom(FishingLayout.Challenge.Wheel.Base);
    this.wheel.state.setAnimation(0, 'pnl_wait', true);

    this.flash = new TickerSpine('fc_wheel');
    this.flash.position.copyFrom(FishingLayout.Challenge.Wheel.Base);

    this.frame = new TickerSpine('fc_wheel');
    this.frame.position.copyFrom(FishingLayout.Challenge.Wheel.Base);
    this.frame.state.setAnimation(0, 'frame_wait', true);

    this.nice = new TickerSpine('fc_wheel');
    this.nice.position.copyFrom(FishingLayout.Challenge.Wheel.ResultText);
    this.nice.state.setAnimation(0, 'nice_loop', true);
    this.nice.visible = false;

    this.roundSpine = new TickerSpine('fc_wheel');
    this.roundSpine.position.copyFrom(FishingLayout.Challenge.Wheel.ResultText);
    this.roundSpine.skeleton.setSkinByName('default');
    this.roundSpine.state.setAnimation(0, 'systemfont', false);
    this.roundSpine.visible = false;

    this.roundText = new AutoResizeText(
      i18n.t('fishing.challenge.round', { cnt: 1, max: 3 }),
      fishingChallengeRoundTextStyles,
    );
    this.roundText.position.copyFrom(FishingLayout.Challenge.Round.landscape.pos);
    this.roundText.anchor.set(0.5);
    this.roundText.alpha = 0;

    this.meter = new FishMeter();

    this.addChild(
      this.sign,
      this.wheel,
      this.frame,
      this.flash,
      this.meter,
      this.nice,
      this.roundSpine,
      this.roundText,
    );
    this.wheelBone = this.wheel.skeleton.findBone('prg_rot');

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.on(EventTypes.FISHING_CHANGE_WHEEL, this.changeWheelState.bind(this));
    eventManager.on(EventTypes.FISHING_WHEEL_SPIN_WAIT, this.waitSpinStart.bind(this));
    eventManager.on(EventTypes.FISHING_WHEEL_SPIN_START, this.startSpin.bind(this));
    eventManager.on(EventTypes.FISHING_CHALLENGE_GOTCHA_END, this.onEndGotcha.bind(this));

    this.parentLayer = layerFishingChallenge;

    //const rot0 = this.wheelBone.rotation;
    this.wheel.state.addListener({
      start(entry) {
        if (entry.animation?.name === 'pnl_rot') {
          //AudioApi.play({ type: ISongs.XT002S_Wheel_Rotate_com });
        }
      },
      complete: (entry: TrackEntry) => {
        if (entry.animation?.name === 'pnl_rot') {
          this.endSpin();
        } else if (entry.animation?.name === 'start') {
          console.log();
        }
      },
      event: (_entry: TrackEntry, event: Event) => {
        const WHEEL_PRIZE_CNT_MAX = 12;
        const WHEEL_ONE_PRIZE_ROT = -360 / WHEEL_PRIZE_CNT_MAX;

        if (this.wheelBone.rotation != null && event.data.name === 'changeRot') {
          const destPos = (WHEEL_PRIZE_CNT_MAX - this.targetPos) % WHEEL_PRIZE_CNT_MAX;
          this.wheelBone.rotation = destPos * WHEEL_ONE_PRIZE_ROT;

          console.log('target:', this.targetPos);
          console.log('dest:', destPos);
          console.log('rotation:', this.wheelBone.rotation);
        }
      },
    });

    this.visible = false;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishKind?: FishKind;
  }) {
    const fishRankToSkins: Record<FishKind, SpineInterface['fc_wheel']['skins']> = {
      Rank1: 'pt01',
      Rank2: 'pt02',
      Rank3: 'pt03',
      Rank4: 'pt04',
      Rank5: 'pt05',
      Rank6: 'pt06',
      Rank7: 'pt07',
    };

    if (isFishingChallengeMode(settings.mode)) {
      this.fishRank = settings.fishKind ?? 'Rank1';
      const skin = fishRankToSkins[this.fishRank];
      this.wheel.skeleton.setSkinByName(skin);
      this.wheel.state.addAnimation(0, 'pnl_wait', true);
      this.frame.skeleton.setSkinByName('wheel_1');
      this.sign.state.setAnimation(0, 'sgin_wait', false);
      this.wheel.update(0);
      this.visible = true;

      this.updateRoundSpine(1, 3);
      this.roundSpine.visible = true;

      this.targetPos = 0;
    } else {
      this.visible = false;
      this.wheel.state.addAnimation(0, 'pnl_wait', true);
    }
  }

  private waitSpinStart() {
    const wheelCount = setFishingResult() ? setFishingResult()?.fishingChallengeWheelCnt ?? 0 : 0;

    this.flash.state.setAnimation(0, 'pnl_change', false);

    if (wheelCount === 0) {
      this.frame.skeleton.setSkinByName('wheel_1');
    } else if (wheelCount === 1) {
      this.frame.skeleton.setSkinByName('wheel_2');
    } else if (wheelCount === 2) {
      this.frame.skeleton.setSkinByName('wheel_3');
    }
    this.roundSpine.visible = false;
  }

  private startSpin() {
    const isSuccess = setFishingResult()?.fishingChallengeResult ? true : false;
    this.roundSpine.visible = false;

    setIsWheelSpinning(true);
    this.targetPos = setFishingResult()?.fishingChallengePosition!;
    this.wheel.state.setAnimation(0, 'pnl_rot', false);
    this.frame.state.setAnimation(0, 'frame_rot', false);

    if (isSuccess) {
      this.sign.state.setAnimation(0, 'sgin_rot_nice', false);
    } else {
      this.sign.state.setAnimation(0, 'sgin_rot_miss', false);
    }
    this.nice.visible = false;

    const delay = Tween.createDelayAnimation(4667);
    delay.addOnComplete(() => {
      if (isSuccess) {
        this.nice.state.setAnimation(0, 'nice_loop', true);
        this.nice.visible = true;
      } else {
        this.nice.visible = false;
      }
    });
    delay.start();
  }

  private endSpin() {
    eventManager.emit(EventTypes.FISHING_WHEEL_SPIN_STOP, setFishingResult()?.fishingChallengeResult ? true : false);
    const wheelCount = setFishingResult() ? setFishingResult()?.fishingChallengeWheelCnt ?? 0 : 0;
    const isSuccess = setFishingResult()?.fishingChallengeResult ? true : false;

    const delayDuration = isSuccess && wheelCount >= 3 ? 100 : 1000;
    const niceDispDelay = Tween.createDelayAnimation(delayDuration);

    this.wheel.state.addAnimation(0, 'pnl_wait', true);
    niceDispDelay.addOnComplete(() => {
      this.nice.visible = false;

      if (isSuccess && wheelCount >= 3) {
        this.meter.setWinCount(wheelCount);
        eventManager.emit(EventTypes.FISHING_CHALLENGE_GOTCHA_START, this.fishRank);
      } else if (isSuccess) {
        this.meter.setWinCount(wheelCount);
        eventManager.emit(EventTypes.COUNT_UP_END);
        setIsWheelSpinning(false); //wait wheel change?

        eventManager.emit(EventTypes.FISHING_CHANGE_WHEEL, wheelCount);
      } else {
        transitionToBaseGame(() => {
          this.wheelBone.rotation = 0;
          setIsWheelSpinning(false);
        });
      }
    });
    niceDispDelay.start();
  }
  private changeWheelState(wheelCnt?: number) {
    if (wheelCnt === 0) {
      BgmControl.playBgm('freeSpin01', false, false);
      this.updateRoundSpine(1, 3);
      this.roundSpine.visible = true;
    } else if (wheelCnt === 1) {
      BgmControl.playBgm('freeSpin02', true, true);
      this.updateRoundSpine(2, 3);
      this.roundSpine.visible = true;
    } else if (wheelCnt === 2) {
      BgmControl.playBgm('freeSpin03', true, true);
      this.updateRoundSpine(3, 3);
      this.roundSpine.visible = true;
    }
  }

  private onEndGotcha() {
    this.visible = false;
    setIsWheelSpinning(false);
  }

  private updateRoundSpine(cnt: number, max: number) {
    this.roundText.text = i18n.t('fishing.challenge.round', { cnt: cnt, max: max });
    this.roundText.updateText(false);
    this.roundSpine.state.setAnimation(0, 'systemfont', false);
    requestAnimationFrame(() => {
      this.roundSpine.hackTextureBySlotName('blank', this.roundText.texture, this.roundText.texture.frame);
    });
  }
  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.x = FishingLayout.Challenge.Container.Landscape.x;
      this.y = FishingLayout.Challenge.Container.Landscape.y;
      this.scale.set(FishingLayout.Challenge.Scale);
      this.wheel.visible = true;
      this.roundSpine.position.copyFrom(FishingLayout.Challenge.Round.landscape.pos);
      this.roundText.style = fishingChallengeRoundTextStyles;
      this.roundText.updateText(false);
    } else {
      this.x = FishingLayout.Challenge.Container.PortRait.x;
      this.y = FishingLayout.Challenge.Container.PortRait.y;
      this.wheel.visible = true;
      this.scale.set(1);
      this.roundSpine.position.copyFrom(FishingLayout.Challenge.Round.Portrait.pos);
      this.roundText.style = fishingChallengeRoundPortraitTextStyles;
      this.roundText.updateText(false);
    }
  };
}

export default FishingChallenge;
