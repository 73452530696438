import * as PIXI from 'pixi.js';
import { Container } from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import { setBetAmount, setCurrency } from '../../gql/cache';
import { showCurrency, transitionToBaseGame } from '../../utils';
import { getFishNameText, getFishRankText, getFishRankTitleText } from '../../utils/i18n';
import Animation from '../animations/animation';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import AutoResizeText from '../components/autoResizeText';
import ViewContainer from '../components/container';
import { layerFishingScaling } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import { FishingLayout } from './config';
import {
  fishingScalingFishNamePortraitTextStyles,
  fishingScalingMultiplierPortraitTextStyles,
  fishingScalingRankPortraitTextStyles,
  fishingScalingRankTitlePortraitTextStyles,
  fishingScalingWinMultiplierPortraitTextStyles,
  fishingScalingWinValuePortraitTextStyles,
} from './textStyle';

const rankToAnim: Record<FishKind, string> = {
  Rank1: 'pt01',
  Rank2: 'pt02',
  Rank3: 'pt03',
  Rank4: 'pt04',
  Rank5: 'pt05',
  Rank6: 'pt06',
  Rank7: 'pt07',
};

class FishScaling extends ViewContainer {
  private base: TickerSpine<'sw'>;
  private effect: TickerSpine<'sw'>;

  private winMessage: TickerSpine<'message_win'>;

  private fishRank: FishKind;
  private layout: 'portrait' | 'landscape';

  private multiplierText: AutoResizeText;
  private rankTitleText: AutoResizeText;
  private rankText: AutoResizeText;
  private fishText: AutoResizeText;

  private winValueText: AutoResizeText;

  private winValueMultiplierText: AutoResizeText;

  private multiplierSceneContainer: Container;
  private winSceneContainer: Container;

  constructor() {
    super();

    this.layout = 'landscape';
    this.fishRank = 'Rank7';

    this.base = new TickerSpine('sw');
    this.base.state.setAnimation(0, 'y_base_pt01', true);
    this.base.position.copyFrom(FishingLayout.Scaling.landscape.base);

    this.effect = new TickerSpine('sw');
    this.effect.state.setAnimation(0, 'y_eff', true);
    this.effect.position.copyFrom(FishingLayout.Scaling.landscape.base);

    this.rankTitleText = new AutoResizeText(getFishRankTitleText(), fishingScalingRankTitlePortraitTextStyles);
    this.rankTitleText.anchor.set(0.5);
    this.rankTitleText.position.copyFrom(FishingLayout.Scaling.landscape.rankTitle);

    this.rankText = new AutoResizeText(getFishRankText('Rank7'), fishingScalingRankPortraitTextStyles);
    this.rankText.anchor.set(0.5);
    this.rankText.position.copyFrom(FishingLayout.Scaling.landscape.rank);

    this.fishText = new AutoResizeText(getFishNameText('Rank7'), fishingScalingFishNamePortraitTextStyles);
    this.fishText.anchor.set(0.5);
    this.fishText.position.copyFrom(FishingLayout.Scaling.landscape.name);

    this.multiplierText = new AutoResizeText('0000', fishingScalingMultiplierPortraitTextStyles);
    this.multiplierText.anchor.set(0.5);
    this.multiplierText.position.copyFrom(FishingLayout.Scaling.landscape.multiplier);

    this.multiplierSceneContainer = new Container();
    this.multiplierSceneContainer.addChild(this.rankTitleText, this.rankText, this.fishText, this.multiplierText);

    //win message
    this.winMessage = new TickerSpine('message_win');
    this.winMessage.state.setAnimation(0, 'base', true);
    this.winMessage.skeleton.setSkinByName('bigwin');

    this.winValueText = new AutoResizeText('', fishingScalingWinValuePortraitTextStyles);
    this.winValueText.anchor.set(0.5);

    this.winValueMultiplierText = new AutoResizeText('', fishingScalingWinMultiplierPortraitTextStyles);
    this.winValueMultiplierText.anchor.set(0.5);

    this.winSceneContainer = this.initButtonContainer(-2000, -2000, 4000, 4000, () => {
      //to basegame
      transitionToBaseGame(() => {
        this.visible = false;
      });
    });
    this.winSceneContainer.addChild(this.winMessage, this.winValueText, this.winValueMultiplierText);
    this.winSceneContainer.visible = false;

    this.addChild(this.base, this.effect, this.multiplierSceneContainer, this.winSceneContainer);

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.addListener(EventTypes.FISHING_SCALING_START, this.start.bind(this));

    this.parentLayer = layerFishingScaling;

    this.visible = false;
  }

  private updateAnime() {
    const ptn = rankToAnim[this.fishRank];
    const layout = this.layout === 'portrait' ? 't' : 'y';
    const base = `${layout}_base_${ptn}` as SpineInterface['sw']['animations'];
    const effect = `${layout}_eff` as SpineInterface['sw']['animations'];

    this.base.state.setAnimation(0, base, false);
    this.effect.state.setAnimation(0, effect, true);
  }
  public start(fishRank: FishKind, multiplier: number) {
    this.multiplierSceneContainer.visible = true;
    this.winSceneContainer.visible = false;
    this.fishRank = fishRank;
    this.winValueText.text = `${formatNumber({
      currency: setCurrency(),
      value: setBetAmount() * multiplier,
      showCurrency: showCurrency(setCurrency()),
    })}`;
    this.visible = true;

    this.updateAnime();

    const winAnimationDelay = Tween.createDelayAnimation(800);
    winAnimationDelay.addOnComplete(() => {
      this.showWinValue();
    });

    const countAnimation = this.creatCountAnimation(multiplier);
    countAnimation.addOnComplete(() => {
      winAnimationDelay.start();
    });
    countAnimation.start();
  }

  private showWinValue() {
    this.winValueMultiplierText.text = this.multiplierText.text;

    this.multiplierSceneContainer.visible = false;
    this.winSceneContainer.visible = true;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishRank?: FishKind;
  }) {
    if (settings.mode === GameMode.SCALING) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  private creatCountAnimation(target: number): Animation {
    const baseWinAnimation = new AnimationGroup({});
    const duration = 5000;

    const value: { winValue: number } = { winValue: 0 };
    const targetMultiplier = target;

    const countUpAnimation = new Tween({
      propertyBeginValue: 1,
      target: targetMultiplier,
      object: value,
      property: TweenProperties.WIN_VALUE,
      update: this.setMultiplierValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.multiplierText.scale,
      propertyBeginValue: 1,
      target: 1.2,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.multiplierText.scale,
      propertyBeginValue: 1,
      target: 1.2,
      property: TweenProperties.Y,
      duration,
    });
    baseWinAnimation.addAnimation(scaleXAnimation);
    baseWinAnimation.addAnimation(scaleYAnimation);
    baseWinAnimation.addAnimation(countUpAnimation);
    return baseWinAnimation;
  }

  private setMultiplierValue(value: number) {
    this.multiplierText.text = `x${Math.floor(value)}`;
  }

  //to common
  private initButtonContainer(x: number, y: number, w: number, h: number, callback: () => void) {
    const container = new PIXI.Container();
    container.interactive = true;
    container.buttonMode = true;
    container.hitArea = new PIXI.Rectangle(x, y, w, h);
    container.on('click', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) callback();
    });
    container.on('touchstart', (ev: PIXI.InteractionEvent) => {
      if (ev.data.isPrimary) callback();
    });

    container.addListener('mouseover', () => {});
    container.addListener('mouseout', () => {});
    container.addListener('mousedown', () => {});
    container.addListener('mouseup', () => {});
    return container;
  }
  private applicationResize = (width: number, height: number): void => {
    const layout = width > height ? FishingLayout.Scaling.landscape : FishingLayout.Scaling.portrait;

    this.base.position.copyFrom(layout.base);
    this.effect.position.copyFrom(layout.base);

    this.rankTitleText.position.copyFrom(layout.rankTitle);
    this.rankText.position.copyFrom(layout.rank);
    this.rankText.position.copyFrom(layout.rank);
    this.fishText.position.copyFrom(layout.name);
    this.multiplierText.position.copyFrom(layout.multiplier);

    this.winMessage.position.copyFrom(layout.winMessage);
    this.winValueText.position.copyFrom(layout.winValueText);
    this.winValueMultiplierText.position.copyFrom(layout.winMultiplier);
    this.winMessage.scale.set(layout.winMessageScale);

    if (width > height) {
      if (this.layout === 'portrait') {
        this.layout = 'landscape';
        this.updateAnime();
      }
    } else {
      if (this.layout === 'landscape') {
        this.layout = 'portrait';
        this.updateAnime();
      }
    }
  };
}

export default FishScaling;
