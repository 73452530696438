import * as PIXI from 'pixi.js';

import { FishKind } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { GameMode } from '../../consts';
import i18n from '../../i18next';
import { isFishingChallengeMode } from '../../utils';
import { getFishNameText, getFishRankText } from '../../utils/i18n';
import AutoResizeText from '../components/autoResizeText';
import ViewContainer from '../components/container';
import { layerFishingChallenge } from '../components/layers/layers';
import { TickerSpine } from '../components/spine';
import { EventTypes, eventManager } from '../config';

import { FishingLayout } from './config';
import { fishingChallengeMeterPortraitTextStyles, fishingChallengeMeterTextStyles } from './textStyle';

class FishMeter extends ViewContainer {
  private base: TickerSpine<'fc_indi'>;
  private textBase: TickerSpine<'fc_indi'>;
  private mark1: TickerSpine<'fc_indi'>;
  private mark2: TickerSpine<'fc_indi'>;
  private mark3: TickerSpine<'fc_indi'>;
  private mark4: TickerSpine<'fc_indi'>;

  private textTitle: AutoResizeText;
  private textRank: AutoResizeText;
  private textName: AutoResizeText;

  private textBaseContainer: PIXI.Container;
  private wheelCount: number;
  private layout: 'portrait' | 'landscape';

  constructor() {
    super();

    this.layout = 'landscape';
    this.wheelCount = 0;
    this.base = new TickerSpine('fc_indi');
    this.base.state.setAnimation(0, 'y_base', true);

    this.textBase = new TickerSpine('fc_indi');
    this.textBase.state.setAnimation(0, 'y_band', true);

    this.mark1 = new TickerSpine('fc_indi');
    this.mark1.state.setAnimation(0, 'y_mark_1', true);
    this.mark1.skeleton.setSkinByName('pt01');

    this.mark2 = new TickerSpine('fc_indi');
    this.mark2.state.setAnimation(0, 'y_mark_2', true);
    this.mark2.skeleton.setSkinByName('pt01');

    this.mark3 = new TickerSpine('fc_indi');
    this.mark3.state.setAnimation(0, 'y_mark_3', true);
    this.mark3.skeleton.setSkinByName('pt01');

    this.mark4 = new TickerSpine('fc_indi');
    this.mark4.state.setAnimation(0, 'y_mark_got', true);
    this.mark4.skeleton.setSkinByName('pt01');

    const textStyle = fishingChallengeMeterTextStyles;
    const layout = FishingLayout.Challenge.Meter.landscape;
    this.textTitle = new AutoResizeText(i18n.t('fishing.challenge.meter.title'), textStyle);
    this.textTitle.position.copyFrom(layout.Title);
    this.textTitle.anchor.set(0, 0.5);

    this.textRank = new AutoResizeText(i18n.t('fishing.challenge.meter.rank1.rank'), textStyle);
    this.textRank.position.copyFrom(layout.Rank);
    this.textRank.anchor.set(0, 0.5);

    this.textName = new AutoResizeText(i18n.t('fishing.challenge.meter.rank1.name'), textStyle);
    this.textName.position.copyFrom(layout.Name);
    this.textName.anchor.set(0, 0.5);

    this.textBaseContainer = new PIXI.Container();
    this.textBaseContainer.addChild(this.textBase, this.textTitle, this.textRank, this.textName);

    this.addChild(this.base, this.textBaseContainer, this.mark1, this.mark2, this.mark3, this.mark4);

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    this.parentLayer = layerFishingChallenge;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
    fishKind?: FishKind;
  }) {
    const fishRankToSkins: Record<FishKind, SpineInterface['fc_indi']['skins']> = {
      Rank1: 'pt01',
      Rank2: 'pt02',
      Rank3: 'pt03',
      Rank4: 'pt04',
      Rank5: 'pt05',
      Rank6: 'pt06',
      Rank7: 'pt07',
    };

    if (isFishingChallengeMode(settings.mode)) {
      const skin = settings.fishKind ? fishRankToSkins[settings.fishKind] : 'default';
      this.base.skeleton.setSkinByName(skin);
      this.textBase.skeleton.setSkinByName(skin);
      this.mark1.skeleton.setSkinByName(skin);
      this.mark2.skeleton.setSkinByName(skin);
      this.mark3.skeleton.setSkinByName(skin);
      this.mark4.skeleton.setSkinByName(skin);
      this.visible = true;

      this.updateText(settings.fishKind!);
    } else {
      this.visible = false;
      this.mark1.visible = true;
      this.mark2.visible = false;
      this.mark3.visible = false;
      this.mark4.visible = false;
    }
  }

  private updateVisible() {
    if (this.wheelCount >= 3) {
      this.mark4.visible = true;
    }
    if (this.wheelCount >= 2) {
      this.mark3.visible = true;
    }
    if (this.wheelCount >= 1) {
      this.mark2.visible = true;
    }
    if (this.wheelCount === 0) {
      this.mark1.visible = true;
      this.mark2.visible = false;
      this.mark3.visible = false;
      this.mark4.visible = false;
    }
  }

  private updateText(fishRank: FishKind) {
    this.textRank.text = getFishRankText(fishRank);
    this.textName.text = getFishNameText(fishRank);
  }
  public setWinCount(winCount: number) {
    this.wheelCount = winCount;
    this.updateVisible();
  }

  private setVisible(visible: boolean) {
    this.visible = visible;
  }

  private initText(isPortrait: boolean) {
    this.textBaseContainer.removeChild(this.textTitle, this.textRank, this.textName);

    const textStyle = isPortrait ? fishingChallengeMeterPortraitTextStyles : fishingChallengeMeterTextStyles;
    const layout = isPortrait ? FishingLayout.Challenge.Meter.Portrait : FishingLayout.Challenge.Meter.landscape;

    this.textTitle = new AutoResizeText(i18n.t('fishing.challenge.meter.title'), textStyle);
    this.textTitle.position.copyFrom(layout.Title);
    this.textTitle.anchor.set(0, 0.5);

    this.textRank = new AutoResizeText(i18n.t('fishing.challenge.meter.rank1.rank'), textStyle);
    this.textRank.position.copyFrom(layout.Rank);
    this.textRank.anchor.set(0, 0.5);

    this.textName = new AutoResizeText(i18n.t('fishing.challenge.meter.rank1.name'), textStyle);
    this.textName.position.copyFrom(layout.Name);
    this.textName.anchor.set(0, 0.5);

    this.textBaseContainer.addChild(this.textTitle, this.textRank, this.textName);
  }

  private applicationResize = (width: number, height: number): void => {
    if (width > height) {
      this.textBaseContainer.position.copyFrom(FishingLayout.Challenge.Meter.landscape.base);
      this.x = FishingLayout.Challenge.Meter.landscape.Container.x;
      this.y = FishingLayout.Challenge.Meter.landscape.Container.y;

      this.base.state.setAnimation(0, 'y_base', true);
      this.textBase.state.setAnimation(0, 'y_band', true);
      this.mark1.state.setAnimation(0, 'y_mark_1', true);
      this.mark2.state.setAnimation(0, 'y_mark_2', true);
      this.mark3.state.setAnimation(0, 'y_mark_3', true);
      this.mark4.state.setAnimation(0, 'y_mark_got', true);

      if (this.layout === 'portrait') {
        this.initText(false);
      }

      this.layout = 'landscape';
    } else {
      this.textBaseContainer.position.copyFrom(FishingLayout.Challenge.Meter.Portrait.base);
      this.x = FishingLayout.Challenge.Meter.Portrait.Container.x;
      this.y = FishingLayout.Challenge.Meter.Portrait.Container.y;

      this.base.state.setAnimation(0, 't_base', true);
      this.textBase.state.setAnimation(0, 't_band', true);
      this.mark1.state.setAnimation(0, 't_mark_1', true);
      this.mark2.state.setAnimation(0, 't_mark_2', true);
      this.mark3.state.setAnimation(0, 't_mark_3', true);
      this.mark4.state.setAnimation(0, 't_mark_got', true);

      if (this.layout === 'landscape') {
        this.initText(true);
      }
      this.layout = 'portrait';
    }
    this.updateVisible();
  };
}

export default FishMeter;
