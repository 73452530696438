import {
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
} from '../config';

import { fishingChallengeRoundPortraitTextStyles, fishingChallengeRoundTextStyles } from './textStyle';

export const FISHING_PRIZE_POS = { x: 960 + SAFE_AREA_LANDSCAPE_PIVOT_X, y: 540 + SAFE_AREA_LANDSCAPE_PIVOT_Y };
export const FISHING_PRIZE_PORTRAIT_POS = { x: 606 + SAFE_AREA_PORTRAIT_PIVOT_X, y: 1078 + SAFE_AREA_PORTRAIT_PIVOT_Y };

export const FishingLayout = {
  Banner: {
    LandScape: {
      Base: { x: 441, y: 429 },
      BaseScale: 1028 / 1210,
      title: { x: 3, y: -528 },
      titleScale: 764 / 1092 / (1028 / 1210),
    },
    Portrait: {
      Base: { x: 441, y: 429 },
      BaseScale: 1,
      title: { x: 3, y: -528 },
      titleScale: 1,
    },
  },
  Challenge: {
    Scale: 880 / 1023,
    Container: {
      Landscape: { x: 484 - 560, y: 88 - 66 },
      PortRait: { x: 7 - 146, y: 402 - 448 },
    },
    Wheel: {
      Base: { x: 623, y: 453 },
      ResultText: { x: 623, y: 453 },
    },
    Sign: {
      Base: { x: 109, y: 484 },
      TextNice: { x: 107, y: 70 + 330 },
      TextMiss: { x: 107, y: 237 + 330 },
    },
    Meter: {
      landscape: {
        Container: { x: 600, y: -40 },
        base: { x: 20, y: 71 },
        Title: { x: -351, y: 0 },
        Rank: { x: -125, y: 0 },
        Name: { x: 129, y: 0 },
      },
      Portrait: {
        Container: { x: 600, y: -200 },
        base: { x: -1, y: 143 },
        Title: { x: -392, y: 0 },
        Rank: { x: -134, y: 0 },
        Name: { x: 150, y: 0 },
      },
    },
    Round: {
      landscape: {
        textStyle: fishingChallengeRoundTextStyles,
        pos: { x: 623, y: 326 },
      },
      Portrait: {
        textStyle: fishingChallengeRoundPortraitTextStyles,
        pos: { x: 623, y: 265 },
      },
    },
  },
  GambleSelect: {
    common: {
      base: { x: 0, y: 0 },
      iconL: { x: -305, y: 23 },
      iconR: { x: 306, y: 22 },
      textL: { x: -311, y: -310 },
      textR: { x: 311, y: -310 },
      gambleBtnArea: { x: -1192 / 2, y: -728 / 2, w: 1192 / 2, h: 728 },
      collectBtnArea: { x: 0, y: -728 / 2, w: 1192 / 2, h: 728 },
    },
    portrait: {
      container: { x: 460, y: 338 },
      containerScale: 1192 / 1192,
      title: { x: 450, y: -149 },
      description: { x: 460, y: 772 },
      cancelBtn: { x: 239, y: 791 },
      okBtn: { x: 700, y: 791 },
    },
    landscape: {
      container: { x: 427, y: 346 },
      title: { x: 428.5, y: -22 },
      description: { x: 456.5, y: 725 },
      containerScale: 1084 / 1192,
      cancelBtn: { x: 228, y: 723 },
      okBtn: { x: 647, y: 723 },
    },
    iconScale: 605 / 1210,
  },
  Gamble: {
    gambleStartBtnArea: { x: -4000 / 2, y: -4000 / 2, w: 4000, h: 4000 },
    landscape: {
      Sign: { x: -15, y: 612 },
      rod: { x: -141, y: 806 },
      meter: { x: 831, y: 358 },
      effect: { x: 372, y: 400 },
      textStart: { x: 370, y: 391 },
      miss: { x: 401, y: 413 },
    },
    portrait: {
      Sign: { x: -9, y: 442 },
      rod: { x: 352, y: 648 },
      meter: { x: 919, y: 334 },
      effect: { x: 439, y: 297 },
      textStart: { x: -91, y: 246 },
      miss: { x: 462, y: 339 },
    },
  },
  Scaling: {
    landscape: {
      base: { x: 400, y: 474 },
      rankTitle: { x: 44, y: -5 },
      rank: { x: 207, y: -5 },
      name: { x: 567, y: -5 },
      multiplier: { x: 349, y: 101 },
      winMessage: { x: 346, y: 193 },
      winValueText: { x: 396, y: 810 },
      winMultiplier: { x: 348, y: 3 },
      winMessageScale: 600 / 1000,
    },
    portrait: {
      base: { x: 460, y: 630 },
      rankTitle: { x: 107, y: -245 },
      rank: { x: 263, y: -245 },
      name: { x: 623, y: -245 },
      multiplier: { x: 451, y: 46 },
      winMessage: { x: 349, y: 101 },
      winValueText: { x: 349, y: 101 },
      winMultiplier: { x: 0, y: 0 },
      winMessageScale: 1,
    },
  },
};
