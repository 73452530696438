import * as PIXI from 'pixi.js';

import type { FishKind, SlotId } from '../../config';
import type { GameMode } from '../../consts';
import type {
  AnticipationInfo,
  AnticipationReelType,
  AnticipationType,
  MessageFreeSpinEndBannerProps,
  MessageFreeSpinsBannerProps,
} from '../../global.d';
import { ISettledBet, ReelSet } from '../../types';
import type Animation from '../animations/animation';
import type Tween from '../animations/tween';
import type { AnnounceType } from '../announce/config';
import type { BgType } from '../background/background';
import type { IWinLine, Icon } from '../d';
import { TransitionProps } from '../transition/d';

import type { SlotMachineState, WinStages } from '.';

export enum EventTypes {
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_REELFRAME_START = 'anticipationReelFrameStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  ANTICIPATION_BONUS_ANIMATIONS_END = 'anticipationBonusAnimationsEnd',
  ANTICIPATION_STOPPED_SLOT_ANIMATIONS_START = 'anticipationStoppedSlotAnimationsStart',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  JINGLE_START = 'jingleStart',
  HIDE_COINS = 'hideCoins',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_LINES = 'hideWinLines',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REELS_STOP_ANIMATION_COMPLETE = 'reelsStopAnimationComplete',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  /** @deprecated */
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_WIN_LABEL_TEXT = 'setWinLabelText',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_RETRIGGER_ANIMATION = 'startRetrigger',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_FADE = 'startFade',
  START_FS_WIN_ANIMATION = 'startFSWinAnimation',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_WIN_LABEL = 'showWinLabel',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_WIN_VISIBILITY = 'setWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  SET_IS_RESPINS_WIN = 'isReSpinWin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_IN_TRANSITION = 'isInTransition',
  SET_IS_SPIN_SHORTCUT_ON = 'isSpinShortCutOn',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  HANDLE_DESTROY_OPENING_SCREEN = 'handleDestroyOpeningScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  BUY_FEATURE_CONFIRMED = 'buyFeatureConfirmed',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  SET_UI_BUTTON_VISIBLE = 'setUIButtonVisible',
  // FS
  END_FREESPINS = 'endFreeSpins',
  SET_FREESPINS_VIEW = 'setFreeSpinsView',

  //FUJI
  START_SPIN_BY_REEL = 'startSpinByReel',
  START_SPIN_BY_REEL_ON_COMPLETE = 'startSpinByReelOnComplete',
  START_CUTIN_ANIMATION = 'startCutinAnimation',
  SET_IS_OPENING_SCENE_PLAYING = 'setIsOpeningScenePlaying',
  START_ANNOUNCEMENT = 'startAnnouncement',
  PHOENIX_START = 'phoenixStart',
  SET_RESPIN_VIEW = 'setRespinView',

  TRANSITION_START = 'transitionStart',

  SET_IS_ALLOW_FORCE_REEL_STOP = 'setIsAllowForceReelStop',

  APP_VISIBILITY_CHANGE = 'appVisibilityChange',

  //FISH
  FISHING_WHEEL_SPIN_WAIT = 'fishingWheelSpinWait',
  FISHING_CHANGE_WHEEL = 'fishingChangeWheel',
  FISHING_WHEEL_SPIN_START = 'fishingWheelSpinStart',
  FISHING_WHEEL_SPIN_STOP = 'fishingWheelSpinStop',
  FISHING_CHALLENGE_GOTCHA_START = 'fishingChallengeGotchaStart',
  FISHING_CHALLENGE_GOTCHA_END = 'fishingChallengeGotchaEnd',
  FISHING_GAMBLE_SELECT_START = 'fishingGambleSelectStart',
  FISHING_GAMBLE_START = 'fishingGambleStart',
  FISHING_CHALLENGE_END = 'fishingChallengeEnd',
  SET_IS_WHEEL_SPINNING = 'setIsWheelSpinning',
  FISHING_G_SELECT_SELECTED = 'fishingGSelectSelected',
  FISHING_SCALING_START = 'fishingScalingStart',

  GAMBLE_CANCEL = 'gambleCancel',
  GAMBLE_CANCEL_SELECTED = 'gambleCancelSelected',
  GAMBLE_SPIN = 'gambleSpin',
  GAMBLE_SELECTED = 'gambleSelected',

  FISHING_ROULETTE_START = 'fishingRouletteStart',
  FISHING_CHALLENGE_WAIT_START = 'fishingChallengeWaitStart',
}

export interface Events {
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: (reachSymbols: SlotId[], reelIdx: number, isRespin: boolean) => void;
  [EventTypes.ANTICIPATION_REELFRAME_START]: (reelIdx: number, type: AnticipationType, durationType: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: () => void;

  [EventTypes.ANTICIPATION_BONUS_ANIMATIONS_END]: () => void;
  [EventTypes.ANTICIPATION_STOPPED_SLOT_ANIMATIONS_START]: (
    reachSymbols: SlotId[],
    reelIdx: number,
    isStopWait?: boolean,
  ) => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: () => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    bgType?: BgType;
    isRetrigger?: boolean;
    fishKind?: FishKind;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageFreeSpinsBannerProps) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageFreeSpinEndBannerProps) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean, allowForceReelStop?: boolean) => void;
  [EventTypes.JINGLE_START]: () => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: (reelId?: number) => void;
  [EventTypes.HIDE_WIN_LINES]: (line: IWinLine[]) => void;
  [EventTypes.HANDLE_CHANGE_BET_AMOUNT]: (betAmount: number) => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; bgType: BgType }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number, anticipationInfo: AnticipationReelType) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;

  [EventTypes.REELS_STOP_ANIMATION_COMPLETE]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (width: number, height: number, scale: number) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_WIN_LABEL_TEXT]: () => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (nextResult: ISettledBet, isTurboSpin: boolean) => void;
  [EventTypes.START_SPIN_ANIMATION]: (isTurboSpin?: boolean) => void;
  [EventTypes.START_RETRIGGER_ANIMATION]: () => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
    bgType: BgType;
  }) => void;
  [EventTypes.START_FADE]: (fadeOutDuration: number, fadeInDuration: number, tint?: number) => void;
  [EventTypes.START_FS_WIN_ANIMATION]: () => void;
  [EventTypes.SHOW_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.SHOW_WIN_LABEL]: () => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: Icon[], reelId?: number) => void;
  [EventTypes.SHOW_TINT]: (state: boolean, reelId?: number) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_WIN_VISIBILITY]: (stage: WinStages) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: number[],
    anticipationInfo: AnticipationInfo,
    announceType: AnnounceType,
    spinResult: Icon[],
  ) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: (resetTint?: boolean) => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.UPDATE_BET]: () => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (spins: number, curr: number, immediately: boolean) => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean, isContinueAutoSpinsAfterFeature: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREESPINS_WIN]: (isFreeSpinWin: boolean) => void;

  [EventTypes.SET_IS_RESPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenedMessageBanner: boolean) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.SET_IS_SPIN_SHORTCUT_ON]: (isShortCutOn: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.HANDLE_DESTROY_OPENING_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: () => void;
  [EventTypes.ENABLE_SOUND_LOADER]: () => void;
  // BuyFeature
  [EventTypes.START_BUY_FEATURE_ROUND]: (id: string) => void;
  [EventTypes.BUY_FEATURE_CONFIRMED]: () => void;
  [EventTypes.HANDLE_BUY_BONUS]: (bonusIdx: number) => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number, isSuper: boolean) => void;
  [EventTypes.SET_UI_BUTTON_VISIBLE]: (visible: boolean) => void;
  [EventTypes.END_FREESPINS]: () => void;
  [EventTypes.SET_FREESPINS_VIEW]: () => void;

  [EventTypes.START_SPIN_BY_REEL]: (reelIndex: number) => void;
  [EventTypes.START_SPIN_BY_REEL_ON_COMPLETE]: (reelIndex: number) => void;
  [EventTypes.START_CUTIN_ANIMATION]: () => void;
  [EventTypes.SET_IS_OPENING_SCENE_PLAYING]: (isOpeningScenePlaying: boolean) => void;
  [EventTypes.START_ANNOUNCEMENT]: (type: AnnounceType) => void;
  [EventTypes.PHOENIX_START]: () => void;
  [EventTypes.SET_RESPIN_VIEW]: () => void;
  [EventTypes.TRANSITION_START]: (settings: TransitionProps) => void;

  [EventTypes.SET_IS_ALLOW_FORCE_REEL_STOP]: (allow: boolean) => void;

  [EventTypes.APP_VISIBILITY_CHANGE]: (isActive: boolean) => void;

  //fishing
  [EventTypes.FISHING_WHEEL_SPIN_WAIT]: () => void;
  [EventTypes.FISHING_CHANGE_WHEEL]: (wheelCnt?: number) => void;
  [EventTypes.FISHING_WHEEL_SPIN_START]: () => void;
  [EventTypes.FISHING_WHEEL_SPIN_STOP]: (isSuccess: boolean) => void;
  [EventTypes.FISHING_CHALLENGE_GOTCHA_START]: (fishRank: FishKind) => void;
  [EventTypes.FISHING_CHALLENGE_GOTCHA_END]: () => void;
  [EventTypes.FISHING_GAMBLE_SELECT_START]: (fishRank: FishKind) => void;
  [EventTypes.FISHING_GAMBLE_START]: (fishRank: FishKind, target: number, result: boolean) => void;
  [EventTypes.FISHING_CHALLENGE_END]: () => void;
  [EventTypes.SET_IS_WHEEL_SPINNING]: (isWheelSpinning: boolean) => void;
  [EventTypes.FISHING_G_SELECT_SELECTED]: (isSelected: boolean) => void;
  [EventTypes.FISHING_SCALING_START]: (fishRank: FishKind, multiplier: number) => void;

  [EventTypes.GAMBLE_CANCEL]: () => void;
  [EventTypes.GAMBLE_CANCEL_SELECTED]: () => void;
  [EventTypes.GAMBLE_SPIN]: () => void;
  [EventTypes.GAMBLE_SELECTED]: () => void;
  [EventTypes.FISHING_ROULETTE_START]: () => void;
  [EventTypes.FISHING_CHALLENGE_WAIT_START]: () => void;
}

export const eventManager = new PIXI.utils.EventEmitter<keyof Events>() as TypedEmitter<Events>;
