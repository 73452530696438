export const spineFile = [
  'bg_basegame',
  'buy',
  'fc_indi',
  'fc_suc',
  'fc_wheel',
  'fg_indi',
  'gamble',
  'message_frame',
  'message_win',
  'prize',
  'reelframe',
  'rod',
  'sw',
  'symbol_a',
  'symbol_b',
  'symbol_c',
  'symbol_d',
  'symbol_e',
  'symbol_f',
  'symbol_sc1',
  'symbol_sc2',
  'symbol_sc3',
  'symbol_sc4',
  'title_logo',
] as const;

export type SpineFile = (typeof spineFile)[number];

export interface SpineInterface {
  bg_basegame: { skins: 'default'; animations: 'base' };
  buy: {
    skins: 'default';
    animations:
      | 'base'
      | 'btn_nml'
      | 'btn_nml_disable'
      | 'btn_nml_over'
      | 'btn_nml_push'
      | 'btn_sp'
      | 'btn_sp_disable'
      | 'btn_sp_ovedr'
      | 'btn_sp_push';
  };
  fc_indi: {
    skins: 'default' | 'pt01' | 'pt02' | 'pt03' | 'pt04' | 'pt05' | 'pt06' | 'pt07';
    animations:
      | 't_band'
      | 't_base'
      | 't_mark_1'
      | 't_mark_2'
      | 't_mark_3'
      | 't_mark_got'
      | 'y_band'
      | 'y_base'
      | 'y_mark_1'
      | 'y_mark_2'
      | 'y_mark_3'
      | 'y_mark_got';
  };
  fc_suc: {
    skins: 'default';
    animations:
      | 'bg'
      | 'prize_pt01'
      | 'prize_pt02'
      | 'prize_pt03'
      | 'prize_pt04'
      | 'prize_pt05'
      | 'prize_pt06'
      | 'prize_pt07'
      | 't_back'
      | 't_front'
      | 'y_back'
      | 'y_front';
  };
  fc_wheel: {
    skins: 'default' | 'pt01' | 'pt02' | 'pt03' | 'pt04' | 'pt05' | 'pt06' | 'pt07' | 'wheel_1' | 'wheel_2' | 'wheel_3';
    animations:
      | 'frame_rot'
      | 'frame_wait'
      | 'nice_loop'
      | 'pnl_change'
      | 'pnl_rot'
      | 'pnl_wait'
      | 'sgin_rot_miss'
      | 'sgin_rot_nice'
      | 'sgin_wait'
      | 'systemfont';
  };
  fg_indi: {
    skins: 'default' | 'pt01' | 'pt02' | 'pt03' | 'pt04' | 'pt05' | 'pt06' | 'pt07';
    animations: 'meter' | 'miss' | 'rod' | 'sgin' | 'start';
  };
  gamble: {
    skins: 'default';
    animations: 'base' | 'off_l' | 'off_r' | 'pt01' | 'pt02' | 'pt03' | 'pt04' | 'pt05' | 'pt06' | 'pt07';
  };
  message_frame: { skins: 'default'; animations: 'base' };
  message_win: { skins: 'default' | 'bigwin' | 'epicwin' | 'greatwin' | 'megawin'; animations: 'base' | 'systemfont' };
  prize: {
    skins: 'default';
    animations:
      | 'front'
      | 'pt01'
      | 'pt02'
      | 'pt03'
      | 'pt04'
      | 'pt05'
      | 'pt06'
      | 'pt07'
      | 'roule_a1'
      | 'roule_a2'
      | 'roule_b1'
      | 'roule_b2'
      | 'roule_c1'
      | 'roule_c2'
      | 'roule_d1'
      | 'roule_d2'
      | 'roule_last_pt01'
      | 'roule_last_pt02'
      | 'roule_last_pt03'
      | 'roule_last_pt04'
      | 'roule_last_pt05'
      | 'roule_last_pt06'
      | 'roule_last_pt07'
      | 'title';
  };
  reelframe: { skins: 'default'; animations: 'back' | 'base' };
  rod: { skins: 'default'; animations: 'base' | 'pt01' | 'pt02' };
  sw: {
    skins: 'default';
    animations:
      | 't_base_pt01'
      | 't_base_pt02'
      | 't_base_pt03'
      | 't_base_pt04'
      | 't_base_pt05'
      | 't_base_pt06'
      | 't_base_pt07'
      | 't_eff'
      | 'y_base_pt01'
      | 'y_base_pt02'
      | 'y_base_pt03'
      | 'y_base_pt04'
      | 'y_base_pt05'
      | 'y_base_pt06'
      | 'y_base_pt07'
      | 'y_eff';
  };
  symbol_a: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'stop' | 'wait' | 'win' };
  symbol_b: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'stop' | 'wait' | 'win' };
  symbol_c: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'stop' | 'wait' | 'win' };
  symbol_d: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'stop' | 'wait' | 'win' };
  symbol_e: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'stop' | 'wait' | 'win' };
  symbol_f: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'stop' | 'wait' | 'win' };
  symbol_sc1: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'stop' | 'wait' | 'win' };
  symbol_sc2: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'stop' | 'wait' | 'win' };
  symbol_sc3: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'stop' | 'wait' | 'win' };
  symbol_sc4: { skins: 'default'; animations: 'longspin' | 'longspin_stop' | 'spin' | 'stop' | 'wait' | 'win' };
  title_logo: { skins: 'default'; animations: 'base' };
}

export const ENABLE_MERGED_ATRAS = true;

export const preloadSpineTextures = [
  'fish_alpha-0.png',
  'fish_alpha-1.png',
  'fish_alpha-2.png',
  'fish_alpha-3.png',
  'fish_alpha-4.png',
  'fish_bw-0.png',
  'fish_no_alpha-0.jpg',
  'fish_no_alpha-1.jpg',
  'fish_no_alpha-2.jpg',
  'fish_no_alpha-3.jpg',
  'reelframe.png',
  'rod.png',
  'symbol_a.png',
  'symbol_b.png',
  'symbol_c.png',
  'symbol_d.png',
  'symbol_e.png',
  'symbol_f.png',
  'symbol_sc1.png',
  'symbol_sc2.png',
  'symbol_sc3.png',
  'symbol_sc4.png',
  'title_logo.png',
];
